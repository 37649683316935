import React from 'react';
import Layout from '../../components/Layout';
import NavThree from '../../components/NavThree';
import PageHeader from '../../components/SchoolHeader';
import Footer from '../../components/Footer';
import ProgramsList from '../../components/ProgramsList';
import NursingProfile from '../../components/NursingPortal';

const MedPsy = () => {
  return (
    <Layout pageTitle='School of Nursing | LUTH'>
      <NavThree />
      <PageHeader title='School of Nursing' />
      {/* <NursingProfile /> */}
      <ProgramsList name='School of Nursing' />
      <Footer />
    </Layout>
  );
};

export default MedPsy;
